import NotFoundView from "./components/views/NotFoundView";
import App from "./components/App";
import { routeMode as $ } from "./utils";
import LayoutRetail from "./retail/view/LayoutRetail";
import ProfileRetail from "./retail/view/ProfileRetail";
import BonusesRetail from "./retail/view/BonusesRetail";
import GarageRetail from "./retail/view/GarageRetail";
import HomeRetail from "./retail/view/HomeRetail";
import { Navigate } from "react-router-dom";
import ChechRetail from "./retail/view/ChechRetail";
import ServiceRetail from "./retail/view/ServiceRetail";
import OrderItemRetail from "./retail/view/OrderItemRetail";

const routesRetail = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: $(<LayoutRetail />),
                children: [
                    { path: 'profile', element: <ProfileRetail /> },
                    { path: 'bonus', element: <BonusesRetail /> },
                    { path: 'order/:orderId', element: <OrderItemRetail /> },
                    { path: 'order', element: <ServiceRetail /> },
                    { path: 'garage', element: <GarageRetail /> },
                    { path: '/', element: <HomeRetail /> },
                    // { path: '/registration', element: registration }
                ]
            },
            { path: 'login', element: <Navigate to="/" /> },
            { path: '*', element: <NotFoundView /> },
            { path: 'check', element: <ChechRetail/>}
        ]
    }
];

export default routesRetail;