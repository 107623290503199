import {createElement, FC, PropsWithChildren, ReactElement} from "react";
import {
    Badge,
    BadgeProps,
    Box,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    useMediaQuery,
} from "@mui/material";
import {NavLink} from "react-router-dom";
import * as React from "react";

export type Props = PropsWithChildren<{
    readonly name: string;
    readonly to?: (string|(() => void));
    readonly icon: ReactElement;
    readonly end?: boolean;
    readonly reload?: boolean;
    readonly badge?: string|number;
    readonly wrapper?: FC;
}>;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: 'white',
        height: '100%',
        right: '-25px',
        top: '13px',
    },
}));

const MenuItem: FC<Props> = (p) => {
    const isSmallPhone = useMediaQuery('(max-width: 425px)', { noSsr: true });

    const child: FC<PropsWithChildren> = (_p) => (
        <ListItemButton>
            <ListItemIcon sx={{ width: 24, height: 24 }}>
                {p.icon}
            </ListItemIcon>
            <StyledBadge invisible={!p.badge}  badgeContent={p.badge} color="warning">
                <ListItemText
                    sx={{
                        color: '#8F95B2',
                        '& .MuiTypography-root': {
                            fontSize: isSmallPhone ? '13px' : 'inherit'
                        }
                    }}
                    primary={p.name}
                />
            </StyledBadge>
            {p.children}
        </ListItemButton>
    );

    const el = (p.to === undefined || typeof p.to == 'function') ? (
            <Box
                component="span"
                children={createElement(child, null, p.children)}
                onClick={p.to as any}
            />
        )
        : (
            <Box
                component={NavLink}
                to={p.to}
                end={p.end}
                reloadDocument={p.reload}
                children={createElement(child, null, p.children)}
            />
        );

    if(p.wrapper)
        return createElement(p.wrapper, null, el);

    return el;
};

export default MenuItem;