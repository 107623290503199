import CartView from "./components/views/CartView";
import CatalogCardView from "./components/views/CatalogCardView";
import CatalogView from "./components/views/CatalogView";
import FavoritesView from "./components/views/FavoritesView";
import NotFoundView from "./components/views/NotFoundView";
import OrderItemView from "./components/views/OrderItemView";
import OrderSuccessView from "./components/views/OrderSuccess";
import OrdersView from "./components/views/OrdersView";
import ProfileView from "./components/views/ProfileView";
import PromoPageView from "./components/views/PromoPageView";
import RecommendedView from "./components/views/RecommendedView";
import SaleoutView from "./components/views/SaleoutView";
import WaitListView from "./components/views/WaitListView";
import WebView from "./components/views/layout/WebView";
import App from "./components/App";
import LoginView from "./components/views/LoginView";
import { routeMode as $ } from "./utils";
import PasswordApprovalView from "./components/views/PasswordApprovalView";
import HomeView from "./components/views/HomeView";

const routes = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: $(<WebView />),
                children: [
                    { path: 'catalog/item/:code', element: <CatalogCardView /> },
                    { path: 'catalog/*', element: <CatalogView /> },
                    { path: 'cart', element: <CartView /> },
                    { path: 'favorites', element: <FavoritesView /> },
                    { path: 'waitlist', element: <WaitListView /> },
                    { path: 'order/success/:orderId', element: <OrderSuccessView /> },
                    { path: 'order/:orderId', element: <OrderItemView /> },
                    { path: 'order', element: <OrdersView /> },
                    { path: 'saleout', element: <SaleoutView /> },
                    { path: 'recommended', element: <RecommendedView /> },
                    { path: 'promo/:promoId', element: <PromoPageView /> },
                    { path: 'profile/:tabItem?', element: <ProfileView /> },
                    { path: '/', element: <HomeView /> },
                ]
            },
            { path: 'login', element: $(<LoginView />, 'public-only') },
            { path: 'password-reset', element: $(<PasswordApprovalView />, 'public-only') },
            { path: '*', element: <NotFoundView /> },
            { path: 'test', element: <HomeView /> },
        ]
    },
];

export default routes;