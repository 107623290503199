import { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Container, Tab, useMediaQuery, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LogoIcon from "../../../icons/logo.svg"

type Props = {

}

const CheckRetail: FC<Props> = () => {

    const theme = useTheme()
    const [value, setValue] = useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const isSmall = useMediaQuery(theme.breakpoints.down('xsm'));
    return (<>
        <Container maxWidth="xl"
            sx={{
                paddingLeft: { xs: 2, lg: 6 },
                paddingRight: { xs: 2, lg: 6 },
            }}
        >
            <Box component={LogoIcon}
                sx={{
                    width: 158,
                    height: 29,
                    my: 2,
                }}
            />
            <TabContext value={value}>
                <Box>
                    <TabList
                        orientation={isSmall ? "vertical" : "horizontal"}
                        onChange={handleChange}
                        sx={{
                            '& .MuiTab-root': {
                                fontSize: {
                                    xs: 20,
                                    xsm: 18,
                                    lg: 20,
                                },
                            },
                            '& .MuiTabs-flexContainer': { justifyContent: "center", alignItems: "center" }
                        }}
                    >
                        <Tab label="Чек" value="1" />
                        <Tab label="Заказ наряд" value="2" />
                        <Button
                            sx={{
                                width: { xs: "100%", xsm: "auto" },
                                fontSize: { xs: 16, xsm: 18, lg: 20, },
                            }}
                            variant="contained"
                        >
                            Оплатить
                        </Button>
                    </TabList>
                </Box>
                <TabPanel value="1">Item One</TabPanel>
                <TabPanel value="2">Item Two</TabPanel>
            </TabContext>
        </Container>

    </>);
};
export default observer(CheckRetail);