import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Box, useTheme } from "@mui/material";

import BannerLink from "../../../components/BannerLink";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonIcon from '@mui/icons-material/Person';

type Props = {

}
const HomeRetail: FC<Props> = () => {

    const theme = useTheme()

    return (<>
        <Box sx={{ pt: 3, display: "flex", gap: 2, flexDirection: {xs: "column", md: "row"} }}>
            <BannerLink
                to="/garage"
                icon={DirectionsCarIcon}
                children="Ваш транспорт"
                sx={{
                    background: 'linear-gradient(168deg, #FFB7F5 0%, #6C5DD3 100%)',
                }}
            />
            <BannerLink
                to="profile"
                icon={PersonIcon}
                children="Профиль"
                sx={{
                    background: 'linear-gradient(283.95deg, #0049C6 -12.57%, #CDF4FF 126.88%)',
                }}
            />
        </Box>
    </>);
};
export default observer(HomeRetail);