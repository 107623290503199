import { FC, ReactElement, forwardRef, useContext, useEffect, useRef, useState } from "react";
import {
    AppBar, Badge, BadgeProps, Box,
    Button,
    CircularProgress,
    Container, Divider, Drawer,
    IconButton,
    Popover, Slide, styled,
    Toolbar, Tooltip, tooltipClasses, TooltipProps, Typography, useMediaQuery,
    useScrollTrigger, useTheme,
} from "@mui/material";
import { AuthContext, BonusContext, UserContext } from "../../../store";
import {
    Outlet,
    useNavigate,
    useSearchParams,
    useLocation, Link,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import LogOutIcon from '../../../icons/logout.svg';
import LogoIcon from '../../../icons/logo.svg';
import MenuIcon from '../../../icons/menu_mobile.svg';
import CloseMenuIcon from '../../../icons/close.svg';

import BonusIcon from "../../../icons/points.svg"
import BonusInfo from "../../../components/bonus/BonusInfo";
import MenuBar, { MenuItem } from "../../../components/MenuBar";
import { Data } from "../../../components/CallMeDialog";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RecordDialog from "../../components/RecordDialog";
import CarCrashIcon from '@mui/icons-material/CarCrash';

type Props = {
};

type Test = {
    children: ReactElement;
}

function HideOnScroll(props: Test) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: 'white',
    },
}));



const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        marginTop: '0 !important',
        padding: 0, margin: 0,
        backgroundColor: 'transparent',
        color: 'inherit'
    },
}));

const BonusPopover = forwardRef((p: { onItemClick?: () => void }, ref) => {

    const bonus = useContext(BonusContext);

    return (<>
        <BonusInfo
            mode="profile"
            balance={bonus.status?.totalBalance || 0}
            available={Math.max(bonus.status?.balance ?? 0, 0)}
            inactive={bonus.status?.inactive ?? 0}
        />
    </>)
})
const WebViewRetail: FC<Props> = () => {
    const auth = useContext(AuthContext);
    // const app = useContext(AppContext);
    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [, setSearchQuery] = useState('');
    const appBarRef = useRef();
    const theme = useTheme();
    const location = useLocation();

    const isSmallMobile = useMediaQuery('(max-width: 450px)', { noSsr: true });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const logOut = () => auth.logOut();
    const wasLoggedIn = auth.wasLoggedIn;
    // const hasPendingRequests = app.hasPendingRequests;

    // const [showBonusAlert, acceptBonusAlert] = app.trueUntilCanceled('BonusSystemAcceptAlert', () => !!bonus.status?.enabled);

    // useEffect(() => {
    //     if (!showBonusAlert) return;

    //     app.alert(<>
    //         Пользуясь сайтом вы даете согласие на участие в <Link target="_blank" to="/static/bonus-agreement.pdf">системе лояльности</Link>.
    //     </>,
    //         {
    //             time: null,
    //             severity: 'warning',
    //             action: (
    //                 <Button
    //                     color="inherit"
    //                     size="medium"
    //                     onClick={() => {
    //                         acceptBonusAlert();
    //                         app.closeSnackbar();
    //                     }}
    //                 >
    //                     Принять
    //                 </Button>
    //             )
    //         }
    //     );

    // }, [showBonusAlert]);

    const itmsMobile = <>
        <MenuItem name="Профиль" to="profile" icon={<AccountCircleIcon />} />
        <MenuItem name="Бонусные баллы" to="bonus" icon={<HistoryIcon />} />
        <MenuItem name="История обслуживания" to="order" icon={<CarCrashIcon />} />
        <MenuItem name="Гараж" to="garage" icon={<DirectionsCarIcon />} />
        <MenuItem name="Выход" to={logOut} icon={<LogOutIcon />} />
        <RecordDialog />
    </>;
    const itms = <>
        <MenuItem name="Профиль" to="profile" icon={<AccountCircleIcon />} />
        <MenuItem name="Бонусные баллы" to="bonus" icon={<HistoryIcon />} />
        <MenuItem name="История обслуживания" to="order" icon={<CarCrashIcon />} />
        <MenuItem name="Гараж" to="garage" icon={<DirectionsCarIcon />} />
        <MenuItem name="Выход" to={logOut} icon={<LogOutIcon />} />
    </>;
    useEffect(() => {
        setSearchQuery(searchParams.get('q') || '');
    }, [searchParams.get('q')]);

    useEffect(() => {
        setAnchorEl(null);
    }, [isMobile, location]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [callProcessing, setCallProcessing] = useState(false);
    const submitCallRequest = (data: Data) => {
        setCallProcessing(true);
        user.requestCall(data)
            .finally(() => setCallProcessing(false))
            ;
    }

    const [menuOpened, setMenuOpened] = useState(false);
    const isLoggedIn = auth.isLoggedIn;
    // const appError = app.error;

    useEffect(() => {
        if (isLoggedIn === false && location.pathname !== '/login')
            navigate('/login');
        else if (isLoggedIn === true && location.pathname === '/login')
            navigate('/');

    }, [isLoggedIn, location.pathname]);

    // if (appError) return <ErrorView />;

    return (<>
        {/* <Box sx={{ position: 'fixed', width: '100%', zIndex: theme.zIndex.modal - 1 }}>
            {hasPendingRequests && <LinearProgress color="secondary" />}
        </Box> */}
        <Container maxWidth="xl"
            sx={{
                paddingLeft: { xs: 2, lg: 6 },
                paddingRight: { xs: 2, lg: 6 },
            }}
        >
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <HideOnScroll>
                    <AppBar ref={appBarRef} color="inherit" sx={{ boxShadow: 'none' }}>
                        <Toolbar sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            minHeight: '69px !important'
                        }}>
                            <Box component={Link} sx={{ height: 29, justifySelf: 'flex-start' }} to="retail">
                                <Box component={LogoIcon}
                                    sx={{
                                        width: 158,
                                        height: 29,
                                        clipPath: isSmallMobile ? 'inset(0 121px 0 0)' : 'none',
                                        position: 'absolute',
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => setAnchorEl(appBarRef.current)}
                                    >
                                        {anchorEl ? <CloseMenuIcon width={32} height={32} /> : <MenuIcon />}
                                    </IconButton>
                                    <Popover
                                        id="menu-appbar"
                                        open={!!anchorEl}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        marginThreshold={0}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            '& .MuiPopover-paper': {
                                                width: '100%',
                                                maxWidth: 'initial',
                                                height: "100%",
                                                p: isSmallMobile ? 1 : 2,
                                                top: '70px!important',

                                            },
                                        }}
                                    >
                                        <Box sx={{
                                            pb: isSmallMobile ? 1 : 2,
                                            pl: 1
                                        }}>
                                            <Button component={Link} to="/profile" variant="text" size="large">
                                                <Typography variant="subtitle1" color={theme.palette.primary.main}>
                                                    {user.user?.fullName}
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <MenuBar onCallRequest={submitCallRequest} callRequestProcessing={callProcessing} children={itmsMobile} />
                                    </Popover>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar sx={{ minHeight: '69px !important' }} />
            </Box>
            <Drawer
                sx={{
                    whiteSpace: 'initial',
                    display: { xs: 'none', sm: 'inline-block' },
                    mr: { sm: 1, md: 1, lg: 6 },
                    position: 'relative',
                    width: {
                        sm: 247,
                        md: 237,
                        lg: 197,
                    },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        left: 'auto',
                        width: 257,
                        transform: {
                            sm: 'translateX(-20px)',
                            lg: 'translateX(-60px)',
                        },
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Box sx={{ px: 2, pt: 4, wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', pb: "28px" }} >
                        <Link to="/" >
                            <LogoIcon width={158} height={29} />
                        </Link>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} variant="fullWidth" />
                    {user.user?.fullName !== undefined ? (<>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Button component={Link} to="/profile" variant="text" size="large">
                                    <Typography variant="subtitle1" color="#3F8CFF">
                                        {user.user?.fullName || user.user?.login}
                                    </Typography>
                                </Button>
                                {bonus.status?.enabled && (<>
                                    <LightTooltip
                                        placement="top"
                                        title={
                                            <BonusPopover
                                                onItemClick={() => setMenuOpened(false)}
                                            />
                                        }
                                        open={menuOpened}
                                        onOpen={() => setMenuOpened(true)}
                                        onClose={() => setMenuOpened(false)}
                                    >
                                        <Box
                                            sx={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                px: "15px",
                                                py: "6px",
                                                backgroundColor: "#F9FAFC",
                                                border: "1px solid #E6E8F0",
                                                height: "100%",
                                                width: "max-content",
                                                borderRadius: "12px"
                                            }}
                                        >
                                            <BonusIcon />
                                            <Typography pl={1} color={theme.palette.info.contrastText} fontSize="24px" fontWeight="bold">
                                                {Math.floor(bonus.status?.totalBalance || 0)}
                                            </Typography>

                                        </Box>
                                    </LightTooltip>
                                </>)}
                            </Box>
                        </Box>
                    </>) : (
                        <Box sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <Divider sx={{ my: 1 }} variant="fullWidth" />
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                        <MenuBar onCallRequest={submitCallRequest} callRequestProcessing={callProcessing} children={itms} />
                        <RecordDialog />
                    </Box>
                </Box>
            </Drawer>
            <Box sx={{
                whiteSpace: 'initial',
                display: 'inline-block',
                position: 'relative',
                width: {
                    xs: '100%',
                    sm: 'calc(100% - 247px - 10px)',
                    md: 'calc(100% - 237px - 10px)',
                    lg: 'calc(100% - 197px - 60px)',
                },
            }} >
                <Outlet />
            </Box>
        </Container>
    </>);
};

export default observer(WebViewRetail);
