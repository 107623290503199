import {createContext} from "react";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {merge, Store} from "./index";
import dayjs, {Dayjs} from "dayjs";
import {request} from "../utils";
import {AxiosResponse} from "axios";
import {APIResponseData} from "./app";
import { Item as CatalogItem } from "../common/item";
import { Cacheable } from "../common/caching";
import { Patch } from "../components/List";

export type OrderItem = {
    readonly id: string;
    readonly item: CatalogItem|null;
    readonly bonus?: {
        value: number;
        deactivated: boolean;
    };
    readonly snapshot: {
        readonly id: string;
        readonly label: string;
        readonly pkg: number;
        readonly price: number;
        readonly cnpCode: number;
        readonly quantity: number;
        readonly inStock: boolean;
        readonly prevPrice: number|null;
        readonly prevQuantity: number|null;
        readonly state: 0 | 1 | 2 | 3;
    }
}

type OrderData = {
    id: string;
    status: number;
    date: Dayjs;
    totalPrice: number;
    receivedByRecipient: boolean;

    address?: string;
    deliveryDate?: Dayjs;
    comment?: string;
    payUntil?: Dayjs;
    bonus?: {
        added: number;
        subtracted: number;
    };
};

export type State = {
    my?: Cacheable & {
        list: ReadonlyArray<Readonly<OrderData>>;
        nextPage: number|null;
        readonly pageCount: number;
    },
    readonly single?: {
        readonly map: Record<string, {
            readonly order: OrderData;
            readonly items: {
                list: OrderItem[];
                nextPage: number|null;
                readonly pageCount: number;
            };
            readonly operations: {
                list: OrderItem[];
                nextPage: number|null;
                readonly pageCount: number;
            };
            readonly recipient: null|{
                readonly id: number;
                readonly name: string;
            };
            readonly payer: null|{
                readonly id: number;
                readonly name: string;
            };
        }>;
    },
};

export class Order {
    _store: Store;
    _state: State = {
        my: undefined,
        single: {
            map: {

            }
        }
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            items: computed,
            setState: action,
        });
    }

    setState(state: State|undefined) {
        if(state?.my?.list) {
            state.my.list = state.my.list.map(order => ({
                ...order,
                date: dayjs(order.date),
                deliveryDate: order.deliveryDate ? dayjs(order.deliveryDate) : undefined,
                payUntil: order.payUntil && dayjs(order.payUntil),
            }));
        }
        if(state?.single) {
            for(const id in state.single.map) {
                (state.single.map[id].order.date as any) = dayjs(state.single.map[id].order.date);
                if(state.single.map[id].order.deliveryDate) {
                    (state.single.map[id].order.deliveryDate as any) = dayjs(state.single.map[id].order.deliveryDate);
                }
                if(state.single.map[id].order.payUntil) {
                    (state.single.map[id].order.payUntil as any) = dayjs(state.single.map[id].order.payUntil);
                }
                for(const item of state.single.map[id].items.list) {
                    if(item.item) {
                        const date = item.item.stockDefaultDate;
                        if(date) {
                            (item.item as any).stockDefaultDate = dayjs(date);
                        }
                    }
                }
            }
        }

        this._state = merge(this._state, state || {});
    }

    createOrder(
        items: { item: CatalogItem, count: number }[],
        comment: string|null,
        addressId: number|null,
        deliveryDateId: string|null,
        userId: number,
        useBonuses = false,
    ) {
        const fd = new FormData;
        for(const item of items) {
            fd.append(`items[${item.item.id}]`, `${item.count}`);
        }
        if(comment)
            fd.append('comment', comment);
        if(addressId)
            fd.append('addressId', addressId+'');
        if(deliveryDateId)
            fd.append('deliveryDateId', deliveryDateId);
        if(useBonuses)
            fd.append('useBonuses', 'true');

        return request({
            method: 'POST',
            url: `/order`,
            data: fd
        })
        .then((res: AxiosResponse<APIResponseData<{ orderId: number }>>) => {
            runInAction(() => {
                this._state.my = undefined;
            });
            this._store.bonus.invalidate();
            this._store.emit('order_created', userId);
            return res;
        });
    }

    approveOrder(id: string, patch: null|Patch, useBonuses = false) {
        const fd = new FormData;
        if(patch) {
            fd.append('patch', JSON.stringify(patch));
        }
        if(useBonuses)
            fd.append('useBonuses', 'true');

        return request({
            method: 'POST',
            url: `/order/${id}/approve`,
            data: fd
        })
            .then(() => {
                runInAction(() => {
                    this._state.my = undefined;
                    delete this._state.single.map[id];
                });
            })
        ;
    }

    setOrderReceived(id: string) {
        return request({
            method: 'POST',
            url: `/order/${id}/received`,
        })
            .then(() => {
                runInAction(() => {
                    this._state.my = undefined;
                    delete this._state.single.map[id];
                });
            })
        ;
    }

    getOrder(orderId: string) {
        if(!this._state.single.map[orderId]) {

            request({
                method: 'GET',
                url: `/order/${orderId}`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
            })
            .then((e) => {
                if(e.data?.meta?.redirect) {
                    const {url, status} = e.data?.meta?.redirect;
                    this._store.app.redirectTo(url, status);
                    return;
                }

                this._store.setState(e.data.state);
            });
        }

        return this._state.single.map[orderId];
    }
    orderItemsLoadNextPage(orderId: string) {

        request({
            method: 'GET',
            url: `/order/${orderId}`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.single.map[orderId].items.nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.state);
        });
    }

    get items() {

        if(this._state.my === undefined) {
            request({
                method: 'GET',
                url: `/order`,
                headers: {'App-Data-Only': 'yes'},
                baseURL: ''
            }).then(res => {
                this._store.setState(res.data.state);
            });
        }

        return this._state.my;
    }
    loadNextPage() {

        request({
            method: 'GET',
            url: `/order`,
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.my.nextPage
            }
        }).then(res => {
            this._store.setState(res.data.state);
        });
    }
}

export const Context = createContext<Order>(undefined);