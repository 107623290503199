import React, {createElement} from "react";
import {Stack, StackProps, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ReactComponentLike} from "prop-types";
import {Link, LinkProps} from "react-router-dom";

type Props = StackProps & LinkProps & {
    readonly icon: ReactComponentLike;
};

const BannerLink = ({ sx, icon, ref, ...p }: Props) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

    const iconSize = isSmall ? 40 : 80;

    return (
        <Stack
            direction={{ xs: 'row-reverse', md: 'column' }}
            justifyContent="space-between"
            alignItems={{ xs: 'center', md: 'initial' }}
            component={Link}
            spacing={2}
            sx={{
                borderRadius: 4,
                p: 2,
                flexGrow: 1,
                flexBasis: 0,
                textDecoration: 'inherit',
                ...(sx || {})
            }}
            {...p}
        >
            {createElement(icon, { style: { alignSelf: 'flex-end', width: iconSize, height: iconSize, color: 'white' }} )}
            <Typography variant="h6" children={p.children} color="white" />
        </Stack>
    );
}

export default BannerLink;