import ListEmptyItem from "../ListEmptyItem";
import {
    Grid,
    Stack,
} from "@mui/material";
import {createElement, FC, RefObject, useEffect, useMemo, useState} from "react";
import { Item } from "../../common/item";
import * as InfiniteScroll from "react-infinite-scroller";
import ListTable from "../Order/ListTable";
import ListTableBonus from "../views/ProfileView/BonusView/ListTable";
import {Order} from "../Order";
import LoadingPaper from "../LoadingPaper";
import {observer} from "mobx-react-lite";
import {fill} from "lodash";
import { CacheableWithPagination } from "../../common/caching";

export type Patch<K extends string|number = string|number, T = {}> = Record<K, null|T>;

type Props = {
    readonly items?: CacheableWithPagination & { list: ReadonlyArray<any> };
    readonly loadNext?: () => void;
    readonly compact?: boolean;
    readonly groups?: ((item: any) => { text: string, condition: () => boolean })[];
    readonly onItemClick?: (item: any) => void;
    readonly onDelete?: (item: any) => void;
    readonly onAmountChange?: (item: any, amount: number) => void;
    readonly component: FC<{ item: any }>;
    readonly variant?: 'grid' | 'table' | 'bonus';
    readonly scrollParentRef?: RefObject<HTMLElement>;
    readonly keyResolver?: (item: any) => string|number;
    readonly noLoader?: boolean;
    readonly noEmptyMessage?: boolean;
    readonly events?: Record<string, CallableFunction>;
    readonly patch?: Patch;
};

export type ListItemItem<T = any> = { type: 'item', item: T };
export type ListItemGroup = { type: 'group', text: string };
export type ListItem = ListItemItem | ListItemGroup;

const ItemGroup = (p: { item: ListItemGroup} ) => {
    return (
        <Grid item xs={12}>
            <strong>{p.item.text}</strong>
        </Grid>
    );
}

const TableWrap = (p: { list: ListItem[], component: FC<{ item: Item|Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number|string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <ListTable
            items={p.list as ListItemItem[]}
            component={p.component as any}
            events={p.events}
            patch={p.patch}
            keyResolver={p.keyResolver}
        />
    );
}

const TableBonus = (p: { list: ListItem[], component: FC<{ item: Item|Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number|string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <ListTableBonus
            items={p.list as ListItemItem[]}
            component={p.component as any}
            events={p.events}
            patch={p.patch}
            keyResolver={p.keyResolver}
        />
    );
}

const SimpleWrap = (p: { list: ListItem[], component: FC<{ item: Item|Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number|string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <Stack spacing={2}>
            {p.list?.map(item => {
                if(item.type === 'group')
                    return <ItemGroup item={item} key={item.text} />;

                const key = p.keyResolver(item.item);
                const patch = p.patch && p.patch[key];

                return createElement(p.component, { item: item.item, key, events: p.events, patch });
            })}
        </Stack>
    );
}

const GridWrap = (p: { list: ListItem[], component: FC<{ item: Item|Order, events?: Record<string, CallableFunction>, patch?: any }>, keyResolver: (item: any) => number|string, compact?: boolean, events?: Record<string, CallableFunction>, patch?: Patch }) => {

    return (
        <Grid container spacing={2}>
            {p.list?.map(item => {
                if(item.type === 'group') {
                    return (
                        <Grid item xs={12} key={item.text}>
                            <ItemGroup item={item} />
                        </Grid>
                    );
                }

                const key = p.keyResolver(item.item);
                const patch = p.patch && p.patch[key];

                return (
                    <Grid item xs={12} xsm={6} sm={12} smm={p.compact ? 4 : 6} lg={p.compact ? 2 : 4} key={p.keyResolver(item.item)}>
                        {createElement(p.component, { item: item.item, events: p.events, patch })}
                    </Grid>
                );
            })}
        </Grid>
    );
}

const pageSize = 24; // TODO: Убрать этот хардкод пагинации

const List: FC<Props> = ({
    variant,
    component,
    items: outerItems,
    loadNext,
    groups,
    scrollParentRef,
    keyResolver,
    compact,
    noLoader,
    noEmptyMessage,
    events,
    patch,
}) => {
    if(!keyResolver)
        keyResolver = (item: any) => item.id;

    const [ nextPage, setNextPage ] = useState(null);
    const [ items, setItems ] = useState(null);

    useEffect(() => {
        if(!outerItems?.list) return;

        if(items) {
            setItems(outerItems.list);
            setNextPage(outerItems.nextPage);
        }
        else {
            const newItems = outerItems.list.slice(0, pageSize);
            setItems(newItems);
            setNextPage(outerItems.list.length > newItems.length ? 2 : outerItems.nextPage);
        }

    }, [ outerItems?.list ]);


    const loadNextProxy = () => {
        if(!nextPage) return;

        if(outerItems.list.length > items.length) {
            const newItems = outerItems.list.slice(0, pageSize * nextPage);
            setItems(newItems);
            if(newItems.length < outerItems.list.length)
                setNextPage(nextPage + 1);
            else
                setNextPage(outerItems.nextPage);
        }
        else if(loadNext) {
            loadNext();
        }
    };


    const list: ListItem[] = useMemo(() => {
        if(!items) return undefined;
        if(!groups) return items.map(item => ({ type: 'item', item }));

        const result: ListItem[] = [];

        const states = fill(Array(groups.length), {}) as { val?: boolean, text?: string }[];
        for(let i = 0; i < items.length; i++) {
            const item = items[i];
            for(let j = 0; j < groups.length; j++) {
                const fn = groups[j];
                const g = fn(item);

                const val = g.condition();
                if(val) {
                    if(states[j].val !== val || g.text !== states[j].text) {
                        result.push({type: 'group', text: g.text});
                        states[j] = { val, text: g.text };
                    }
                }
                else if(states[j].val !== val) {
                    states[j] = { val, text: null };
                }
            }
            result.push({ type: 'item', item });
        }

        return result;

    }, [ items, groups ]);

    const wrap = {
        'grid': GridWrap,
        'table': TableWrap,
        'bonus': TableBonus,
    }[variant] || SimpleWrap;

    return (
        <Stack spacing={2}>
            {list ? (
                list.length ? (
                    <InfiniteScroll
                        loadMore={loadNextProxy}
                        hasMore={!!nextPage}
                        initialLoad={false}
                        getScrollParent={scrollParentRef?.current ? () => scrollParentRef.current : undefined}
                        useWindow={!scrollParentRef?.current}
                        loader={
                            <LoadingPaper key="loader" sx={{ mt: 2 }} />
                        }
                    >
                        {createElement(wrap, { list, component, keyResolver, compact, events, patch })}
                    </InfiniteScroll>
                ) : (<>
                    {noEmptyMessage ? <></> : <ListEmptyItem />}
                </>)
            ) : (<>
                {noLoader ? <></> : <LoadingPaper />}
            </>)}
        </Stack>
    );
}

export default observer(List);