import {createContext} from "react";
import {action, computed, makeObservable, observable} from "mobx";
import {merge, Store} from "./index";
import {request, toFormData, ToFormDataArg} from "../utils";
import { CacheableWithPagination } from "../common/caching";

export type VehicleItem = {
    readonly id: number;
    readonly status: 'manual_pending' | 'fetched' | 'manual_fail';
    readonly name: string;
    readonly stateCode: string;
    readonly vinCode: string;
    readonly year: number;
    readonly enginePowerHp: number;
    readonly engineCapacityCm: number;
} | {
    readonly id: number;
    readonly status: 'statecode_pending';
    readonly stateCode: string;
    readonly name: string;
} | {
    readonly id: number;
    readonly status: 'statecode_fail';
    readonly stateCode: string;
    readonly name: string;
};

export type State = {
    readonly items?: CacheableWithPagination & {
        list: VehicleItem[];
    }
};

export class Garage {
    _store: Store;
    _state: State = {
        items: undefined,
    };

    constructor(store: Store) {
        this._store = store;

        makeObservable(this, {
            _state: observable,
            items: computed,
            setState: action,
        });
    }

    setState(state: State | undefined) {
        this._state = merge(this._state, state || {});
    }

    async createVehicleItemByStateCode(d: ToFormDataArg<{
        readonly stateCode: string;
    }>) {
        await request({
            method: 'POST',
            url: `/garage/vehicle/stateCode`,
            data: toFormData(d),
        })
        .then(res => {
            this._store.setState(res.data.state);
        })
    }

    get items() {
        if(this._state.items === undefined) {

            request({
                method: 'GET',
                url: '/garage',
                headers: {'App-Data-Only': 'yes'},
                baseURL: '',
            })
            .then(res => {
                this._store.setState(res.data.state);
            })
        }

        return this._state.items;
    }
    loadNextPage() {

        request({
            method: 'GET',
            url: '/saleout',
            headers: {'App-Data-Only': 'yes'},
            baseURL: '',
            params: {
                page: this._state.items.nextPage,
            }
        })
        .then(res => {
            this._store.setState(res.data.state);
        })
    }

    async bookService(d: ToFormDataArg<{
        readonly stateCode: string;
        readonly date: string;
        readonly comment?: null|string;
    }>) {
        await request({
            method: 'POST',
            url: `/garage/service-book`,
            data: toFormData(d),
        })
        .then(res => {
            this._store.setState(res.data.state);
        })
    }
}

export const Context = createContext<Garage>(undefined);