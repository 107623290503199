import { observer } from "mobx-react-lite";
import { useContext, useState, forwardRef } from "react";
import { BonusContext, UserContext } from "../../../../store";
import {
    Box,
    Paper,
    Tooltip,
    TooltipProps,
    Typography,
    Unstable_Grid2 as Grid,
    styled,
    tooltipClasses,
    useMediaQuery,
    useTheme
} from "@mui/material";
import BonusIcon from "../../../../icons/points.svg"
import BonusInfo from "../../../../components/bonus/BonusInfo";
import QRIdentity from "../../../components/QRIdentity";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        marginTop: '0 !important',
        padding: 0, margin: 0,
        backgroundColor: 'transparent',
        color: 'inherit'
    },
}));

const BonusPopover = forwardRef((p: { onItemClick?: () => void }, ref) => {

    const bonus = useContext(BonusContext);

    return (<Box>
        <BonusInfo
            mode="profile"
            balance={bonus.status?.totalBalance || 0}
            available={Math.max(bonus.status?.balance ?? 0, 0)}
            inactive={bonus.status?.inactive ?? 0}
        />
    </Box>)
})
const InfoViewRetail = () => {

    const bonus = useContext(BonusContext);
    const user = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const data = user.user;
    const [menuOpened, setMenuOpened] = useState(false);

    return (<>
        <Paper sx={{ p: "15px", display: "flex", justifyContent: "space-between", flexDirection: "column", my: 3, gap: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12} md={9}>
                    <Box>
                        <Box>
                            <Typography fontWeight="bold" color="#3F8CFF" variant={isSmall ? "h6" : "h5"} children={user.user?.fullName} />
                            <Typography p="10px 0 15px 0" color="#081735" variant={isSmall ? "h6" : "h5"}>
                                Логин: {data.login}
                            </Typography>
                        </Box>
                    </Box>
                    <BonusPopover />
                </Grid>
                <Grid xs={12} md={3}>
                    <QRIdentity sx={{ width: '100%'}} />
                </Grid>
            </Grid>
        </Paper>
    </>);
}

export default observer(InfoViewRetail);
